<template>
    <v-container class="my-10 resources">
        <h2 class="text-h4 mb-5 pb-5">{{ $t('resources.title') }}</h2>
        <div id="services">
            <v-row>
                <v-col>
                    <img src="../assets/homepage/boss.svg">
                    <p class="pt-5"><strong v-html="$t('resources.option1')"></strong></p>
                </v-col>
                <v-col>
                    <img src="../assets/homepage/educacion.svg">
                    <p class="pt-5"><strong v-html="$t('resources.option2')"></strong></p>
                </v-col>
                <v-col>
                    <img src="../assets/homepage/companero-de-negocios.svg">
                    <p class="pt-5"><strong v-html="$t('resources.option3')"></strong></p>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
    created(){
        console.log('asd')
    },
}
</script>

<style scoped>
    .resources{
        text-align: center;
        font-size: 20px;
    }
    .resources img{
        width: 40%;
    }
</style>